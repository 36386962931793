<template>
  <div class="jackpot_wrapper">
    <div class="jackpot jackpot_loader">
      <div class="card_img skeleton-item skeleton-item1"></div>
      <div class="skeleton-item-card">
        <div class="btn skeleton-item skeleton-item2"></div>
      </div>
    </div>
    <div class="jackpot jackpot_loader">
      <div class="card_img skeleton-item skeleton-item1"></div>
      <div class="skeleton-item-card">
        <div class="btn skeleton-item skeleton-item2"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "JackpotLoader",
  props: {
    count: {
      type: Number,
      default: 1,
    },
  },
};
</script>

<style scoped src="./index.css"></style>
