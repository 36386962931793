<template>
  <div class="jackpot">
    <div class="card_img">
      <img
        :src="
          this.currentLanguage === 'fr'
            ? '/img/banners/500-fr.webp'
            : '/img/banners/500-en.webp'
        "
        alt="jackpot"
      />
    </div>
    <div class="cardd">
      <div class="inner_card">
        <h4 class="card_title">
          {{ $t("predict") }} {{ jackpot.number_of_games }}
          {{ $t("matchestoWin") }} {{ jackpot.prize }} {{ fiatCurrency }}
        </h4>
        <p>{{ $t("prizesCorrectPrediction") }}</p>
        <p>
          <JackpotTicket /><span>
            {{ $t("ticketPrice") }}: {{ jackpot.minimum_stake || "_" }}
            {{ fiatCurrency }}
          </span>
        </p>
        <div>
          <JackpotClockIcon />
          <p>{{ $t("closingTime") }}: {{ formatJackpotEnd(jackpot.end) }}</p>
        </div>
        <p>- {{ $t("subjectToTax") }}</p>
      </div>
      <router-link
        class="btn"
        @click="playJackpot"
        :to="`/play-jackpot/${jackpot.id}`"
      >
        <ChopbetButton>{{ $t("playJackpot") }}</ChopbetButton>
      </router-link>
    </div>
  </div>
</template>

<script>
import JackpotClockIcon from "../../../components/icons/JackpotClockIcon.vue";
import JackpotTicket from "../../../components/icons/JackpotTicket.vue";
import ChopbetButton from "../../../components/ui/ChopbetButton.vue";

export default {
  name: "JackpotCard",
  data() {
    return {
      fiatCurrency: process.env.VUE_APP_CURRENCY,
    };
  },
  props: {
    ticket: {
      type: String,
      default: `0.50 ${process.env.VUE_APP_CURRENCY}`,
    },
    time: {
      type: String,
      default: `21:40 ${process.env.VUE_APP_CURRENCY}`,
    },
    date: {
      type: String,
      default: "Saturday, 07/09/24",
    },
    title: {
      type: String,
      default: `Predict 12 Matches to Win 250,000 ${process.env.VUE_APP_CURRENCY}`,
    },
    jackpot: {
      type: Object,
      default: null,
    },
  },
  components: {
    JackpotTicket,
    JackpotClockIcon,
    ChopbetButton,
  },
  methods: {
    formatJackpotEnd(date) {
      const options = {
        weekday: "long",
        year: "2-digit",
        month: "2-digit",
        day: "2-digit",
        hour: "2-digit",
        minute: "2-digit",
        hour12: false,
      };
      const timeLanguage = this.currentLanguage === "fr" ? "fr-FR" : "en-US";
      const formattedDate = new Intl.DateTimeFormat(
        timeLanguage,
        options
      ).format(new Date(date));

      return formattedDate
        .replace(",", " •")
        .replace(" AM", "")
        .replace(" PM", "");
    },
    playJackpot() {
      console.log(this.jackpot, "jackpotjackpotjackpotjackpot");
      // this.$store.dispatch("setJackpotPrice", theme);
    },
  },
  computed: {
    currentLanguage() {
      return this.$i18n.locale;
    },
  },
};
</script>

<style scoped>
/* .tax {
  margin-top: 5px;
  font-size: 10px;
  color: var(--sub-text-color);
} */
h4 {
  margin: 0;
}
.jackpot {
  margin-bottom: 1rem;
}
.btn {
  padding: 0;
  width: 100%;
  font-size: 10px;
}
h4 {
  color: var(--text-color);
  font-size: 14px !important;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.14px;
}
.cardd {
  display: flex;
  padding: 16px 16px 12px;
  flex-direction: column;
  align-items: flex-start;
  gap: 16px;
  background-color: var(--box-bg);
  color: var(--text-color);
  border-radius: 0 0 5px 5px;
}
.card_title {
  color: var(--text-color);
  font-size: 13px;
  font-weight: 700;
  letter-spacing: -0.14px;
}

.card_items {
  display: flex;
  font-size: 10px;
  align-items: center;
  gap: 5px;
}
.card_items p {
  color: var(--Light-Mode-Base-Base-600, #545151);
  font-size: 10px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.1px;
  margin-bottom: 9px;
}
.card_items svg {
  margin-top: -11px;
}
p {
  margin: 0;
}
.inner_card {
  display: flex;
  flex-direction: column;
  gap: 4px;
}
.inner_card p,
.inner_card div {
  display: flex;
  align-items: center;
  gap: 4px;
  font-weight: 700;
  font-size: 10px;
  color: var(--sub-text-color);
}
.card_img {
  height: 119px;
  width: 100%;
  border-radius: 5px 5px 0 0;
  position: relative;
}
.card_img img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px 5px 0 0;
}
.bonus_card {
  width: 100%;
  height: 100%;
  padding: 16px;
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-items: center;
  top: 0;
  border-radius: 5px 5px 0 0;
}
.bonus_card img:first-child {
  width: 100px;
  height: 30px;
  object-fit: fill;
}
.bonus_card img:last-child {
  width: 120px;
  height: 30px;
  object-fit: fill;
}
.bonus_card span {
  color: #efff6d;
}
</style>
